import {ArticleJsonLd, GatsbySeo} from "gatsby-plugin-next-seo";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {LegalViewModel} from "../../application/model/legal-view-model";

export interface LegalSeoProps {
	viewModel: LegalViewModel
}

export default function LegalSeo({viewModel}: LegalSeoProps) {

	const {language, t} = useI18next();

	return (
		<>
			<GatsbySeo
				noindex={true}
				nofollow={true}
				language={language}
			/>
		</>
	)
}

import {graphql} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import Layout from "../components/layout";
import ComposeLegalViewModelFromGraph from "../modules/legal/application/useCase/compose-legal-view-model-from-graph";
import LegalPageContent from "../modules/legal/presentation/pages/legal-page-content";
import LegalSeo from "../modules/legal/presentation/seo/legal-seo";

export const query = graphql`
query ($language: String!, $pageSlug:String!) {
  directus {
    pages_legal(filter: {translations: {page_slug: {_eq: $pageSlug}}}) {
      id
      title
      status
      date_created
      date_updated
      translations {
        id
        content_title
        content_text
        content_subtitle
        page_description
        page_keywords
        page_slug
        page_title
        languages_code {
        	locale
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`

export default function LegalTemplate({data}: any) {

	const {language} = useI18next();
	const usecase = new ComposeLegalViewModelFromGraph(data);
	const viewModel = usecase.execute(language);

	return (
		<>
			<LegalSeo viewModel={viewModel}/>
			<Layout>
				<LegalPageContent viewModel={viewModel}/>
			</Layout>
		</>
	)
}

import {ArticleTranslationViewModel} from "../../../article/submodule/article/presentation/model/article-translation-view-model";
import {ViewModel} from "../../../shared/building_blocks/view_model";

export interface LegalViewModelProps {
	slug: string
	locale: string
	heading: string
	body: string
}

export class LegalViewModel extends ViewModel<LegalViewModelProps> {

	private constructor(props: LegalViewModelProps) {
		super(props);
	}

	public static create(props: LegalViewModelProps): LegalViewModel {
		return new LegalViewModel({...props})
	}

}

import parse, {domToReact, Element, HTMLReactParserOptions} from "html-react-parser";
import React from "react";
import {useAppStore} from "../../../../state/app_state";
import ArticleHeadingH3 from "../../../article/submodule/article/presentation/component/content/article-content-heading-h3";
import {LegalViewModel} from "../../application/model/legal-view-model";

export interface LegalPageContentProps {
	viewModel: LegalViewModel
}

export default function LegalPageContent({viewModel}: LegalPageContentProps) {

	// State
	const setLegalSection = useAppStore(state => state.setLegal);
	setLegalSection();

	return (
		<>
			<div className="layout-containerized font-sans my-12">
				<h1 className="text-4xl my-1">{viewModel.props.heading}</h1>
				<LegalContentParser htmlInput={viewModel.props.body}/>
			</div>
		</>
	)
}


export interface LegalContentParserProps {
	htmlInput: string,
}

export function LegalContentParser({htmlInput}: LegalContentParserProps) {

	const options: HTMLReactParserOptions = {
		trim: true,
		replace: (domNode) => {

			if (!domNode) {
				return;
			}
			const dom: any = domNode

			if (dom.attribs && dom.name === "p") {
				if (domNode instanceof Element) {
					return (
						<p className={"my-5"}>
							{domToReact(domNode.children, options)}
						</p>
					);
				}
			}

		}
	}

	const parsed = parse(htmlInput, options)

	// Return as Element
	return parsed as JSX.Element
}
